<template>

  <!--  Style Parameters-->
  <div v-if="isStyle">
    <ws-button-group
        @input="$emit('input' , this.COPY(this.element.config) )"
        v-model="element.config.vertical"
        :items="orientationSelect"
        class="mb-2 mt-2"
    />

    <a-parameter-slider
        @change="$emit('input' , this.COPY(this.element.config) )"
        class="mt-4"
        v-model="element.config.thickness"
        :default="0"
        :value-color="wsACCENT"
        label="Thickness"
        min="0"
        max="14"
    />

  </div>
</template>

<script>

export default {
  name: "avalonTextSettings",
  components : {

  },
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    isStyle : {
      type : Boolean,
      default : false
    },
    element : {
      type : Object,
      default() { return {} }
    },
    blockStyle : {
      type : String,
      default : 'light_1'
    }
  },
  data() {
    return {
      config : {}
    }
  },
  computed : {
    orientationSelect() {
      return [
        { text : this.$t('HorizontalLine') , value : false },
        { text : this.$t('VerticalLine') , value : true },
      ]
    }
  },
  methods : {
  },
  mounted() {
    if (!this.element.config.vertical) {
      this.element.config.vertical = false
      this.element = this.COPY(this.element)
    }
    this.config = this.COPY(this.value)
  }

}
</script>

<style scoped>
</style>